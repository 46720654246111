import React, { useState, useEffect, useCallback } from "react";
import AxiosApi from '../../Api';
import styles from "./DecimationHeader.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import DecimationField from "../DecimationField/DecimationField";

const DecimationHeader = ({ initialDecimation, rtu_id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [decimation, setDecimation] = useState(initialDecimation);

    const refreshDecimation = useCallback(async () => {
        setIsLoading(true);
        try {
            const result = await AxiosApi.get(`/decimation/${rtu_id}/site`);
            if (result.data.code === 200) {
                setDecimation(result.data.data);
            } else {
                alert.show("Connection error");
            }
        } catch (error) {
            console.error("Error fetching decimation data:", error);
        } finally {
            setIsLoading(false);
        }
    }, [rtu_id]);

    useEffect(() => {
        let isMounted = true; // Track if the component is mounted

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const result = await AxiosApi.get(`/decimation/${rtu_id}/site`);
                if (isMounted && result.data.code === 200) {
                    setDecimation(result.data.data);
                }
            } catch (error) {
                console.error("Error fetching decimation data:", error);
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false; // Cleanup function to prevent state updates
        };
    }, [rtu_id]);

    return (
        <div className={!initialDecimation ? "p-2" : ""}>
            <div className="row">
                <div className="col">
                    <p className="card-text"><small className="text-muted">Decimaci&oacute;n</small></p>
                </div>
                <div className="col">
                    <small>
                        {isLoading ? (
                            <span className="btn btn-sm">
                                <FontAwesomeIcon icon={faSpinner} className={`fa-spin ${styles.spinSize}`} /> &nbsp;Cargando
                            </span>
                        ) : (
                            <button className="btn btn-link btn-sm" title="Actualizar" type="button" onClick={refreshDecimation}>
                                <FontAwesomeIcon icon={faCloudDownloadAlt} size="sm" /> &nbsp;Actualizar
                            </button>
                        )}
                    </small>
                </div>
            </div>
            {decimation && decimation.length > 0 && (
                <>
                    <DecimationField reportDate={decimation[0].report_date1} value={decimation[0].data1} isLoading={isLoading} />
                    <DecimationField reportDate={decimation[0].report_date2} value={decimation[0].data2} isLoading={isLoading} />
                    <DecimationField reportDate={decimation[0].report_date3} value={decimation[0].data3} isLoading={isLoading} />
                </>
            )}
        </div>
    );
};

export default DecimationHeader;