import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./Login.module.css";
import { useForm } from "react-hook-form";
import AxiosApi from '../../Api';

const Login = () => {
  const { register, handleSubmit, errors } = useForm();
  const [message, setMessage] = useState();
  const history = useHistory();

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setMessage({
      data: "Iniciando sesión...",
      type: "alert-warning",
      disabled: true,
    });
    localStorage.clear();
    const loginData = {username: data.username, password: data.password};
    try {
      const result = await AxiosApi.post(`/${data.username}/login`, loginData);
        if(result.data.code === 200) {
          setMessage({
            data: "",
            type: "",
          });
          sessionStorage.setItem("name", result.data.data.name);
          sessionStorage.setItem("token", result.data.data.accessToken);
          sessionStorage.setItem("username", result.data.data.username);
          
          history.push("/dashboard");
        } else {
          setMessage({
            data: result.data.desc,
            type: "alert-danger",
            disabled: false,
          });
        }
      } catch (err) {
        console.error(err);    // ***
        setMessage({
          data: "No es posible iniciar sesión, intente nuevamente",
          type: "alert-danger",
          disabled: false,
        });
      }
  };

  return (
    <div
      className={`${styles.container} container-fluid d-flex align-items-center justify-content-center`}
    >
      <div className={styles.loginFormContainer}>
        {message && (
          <div
            className={`alert fade show d-flex ${message.type} text-center`}
            role="alert"
          >
            {message.data}
          </div>
        )}
        <fieldset className="border p-3 rounded">
          <legend
            className={`${styles.loginFormLegend} border rounded p-1 text-center`}
          >
            <img src="logo_login.png" className="img-fluid" alt="Pipe eagle"/>
          </legend>
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="form-group">
              <input 
                disabled={message && message.disabled}
                id="inputForUsername"
                name="username"
                type="username"
                className="form-control"
                aria-describedby="Ingrese el usuario"
                placeholder="Usuario"
                autoComplete="username"
                ref={register({
                  required: {
                    value: true,
                    message: "Por favor ingrese su nombre de usuario",
                  },
                })}
              />
              {/**
               * we provide validation configuration for username field above
               * error message are displayed with code below
               */}
              {errors.username && (
                <span className={`${styles.errorMessage} mandatory`}>
                  {errors.username.message}
                </span>
              )}
            </div>
            <div className="form-group">
              <input
                disabled={message && message.disabled}
                type="password"
                name="password"
                className="form-control"
                id="inputForPassword"
                placeholder="Contraseña"
                autoComplete="current-password"
                ref={register({
                  required: {
                    value: true,
                    message: "Por favor ingrese la contraseña",
                  },
                })}
              />
              {errors.password && (
                <span className={`${styles.errorMessage} mandatory`}>
                  {errors.password.message}
                </span>
              )}
            </div>
            <div className="d-flex align-items-center">
              <button type="submit" className="btn btn-outline-primary" disabled={message && message.disabled}>
                Ingresar
              </button>
            </div>
          </form>
        </fieldset>
      </div>
    </div>
  );
};

export default Login;