import React, { useEffect, useState, useCallback } from "react";
import AxiosApi from '../../Api';
import styles from "./Dashboard.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import NavBar from "../../UI/NavBar/NavBar";
import RTUData from "../../UI/RTUData/RTUData";

const Dashboard = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [siteData, setSiteData] = useState([]);
  const [sections, setSections] = useState([]);
  const [error, setError] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const filterSites = ({ name, mobile_id, rtu_id }) => {
    const search = searchValue.toLowerCase();

    return (name || "").toLowerCase().indexOf(search) !== -1 ||
      (mobile_id || "").toLowerCase().indexOf(search) !== -1 ||
      (rtu_id || "").toLowerCase().indexOf(search) !== -1;
  };

  const loadSectionData = useCallback(async (section) => {
    setIsLoading(true);
    setIsLoadingData(true);
    setFetchError(null);

    const requests = [
      AxiosApi.get('/decimation/' + section),
      AxiosApi.get('/' + section)
    ];

    Promise.all(requests).then((result) => {
      const decimationResponse = result[0];
      const response = result[1];

      if (decimationResponse.status === 200 && response.status === 200) {
        const sites = response.data.data;
        const decimation = decimationResponse.data.data;
        setSiteData({ sites, decimation });
      } else {
        setFetchError("Error al cargar la información del RTU");
      }
    }).catch((error) => {
      console.error("Error response:", error);
      setFetchError("Error al cargar la información del RTU");
    }).finally(() => {
      setIsLoading(false);
      setIsLoadingData(false);
    });

  }, []);

  const loadSections = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    const parsedSections = localStorage.getItem("sections");
    if(parsedSections) {
      const sections = JSON.parse(parsedSections)
      setSections(sections);
      const selected = localStorage.getItem("selectedOption") || null;
      if(selected) {
        const selectedName = sections.find(x => x.section === selected)?.name;
        setSelectedOption(selectedName);
        loadSectionData(selected); 
      }
      setIsLoading(false);
    }
    else
    {
      AxiosApi.get('/sections').then((result) => {
        if (result.status === 200 && result.data.code === 200) {
          const sectionsData = result.data.data;
          setSections(sectionsData);

          // Save sections to localStorage
          localStorage.setItem("sections", JSON.stringify(sectionsData));
        } else {
          setError("Error al cargar la información del RTU");
        }
      }).catch((error) => {
        console.error("Error response:", error);
        setError("Error al cargar la información del RTU");
      }).finally(() => {
        setIsLoading(false);
      });
    }

  }, [loadSectionData]);

  useEffect(() => {
    // Only load sections if not already loaded
    if (sections.length === 0) {
      loadSections();
    }
  }, [loadSections, sections]);

  const handleSelect = (eventKey) => {
    const selected = sections.find(x => x.section === eventKey).name;
    setSelectedOption(selected);

    // Save the selected option to localStorage
    localStorage.setItem("selectedOption", eventKey);

    loadSectionData(eventKey);
  };

  return (
    <>
      <NavBar />
      {
        isLoading ? (
          <div className="container">
            <div className="row justify-content-center p-2">
              <FontAwesomeIcon icon={faSpinner} className={`fa-spin ${styles.spinSize}`} />
            </div>
          </div>
        ) : (
          error ? (
            <div className="container">
              <div className="row justify-content-center p-2">
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              </div>
              <div className="row justify-content-center">
                <button className="btn btn-link btn-sm" title="Actualizar" type="button" onClick={loadSections} >
                  <FontAwesomeIcon icon={faCloudDownloadAlt} size="sm" /> &nbsp;Reintentar
                </button>
              </div>
            </div>
          ) : (
            <div>
              {sections && sections.length > 0 && (
                <div className="container">
                  <div className="row justify-content-center p-2">
                    <DropdownButton
                      disabled={isLoadingData}
                      id="dropdown-basic-button"
                      title={selectedOption || "Seleccione una opción"}
                      onSelect={handleSelect}
                    >
                      {
                        sections.map(x => (
                          <Dropdown.Item key={x.section} eventKey={x.section}>{x.name}</Dropdown.Item>
                        ))
                      }
                    </DropdownButton>
                  </div>
                </div>
              )}
              {siteData && siteData.sites && siteData.sites.length > 0 ? (
                <div className="container">
                  <div className="row justify-content-center p-2">
                    <input
                      className="search mb-3 mt-3"
                      type="text"
                      placeholder="Filtrar"
                      onChange={(e) => setSearchValue(e.target.value)}
                      value={searchValue} />
                  </div>
                  {
                    siteData.sites.filter(filterSites).map(q =>
                      <RTUData key={q.Key} siteData={q} decimation={siteData.decimation} />
                    )
                  }
                </div>
              ) : (
                selectedOption && isLoadingData ? (
                  <div className="container">
                    <div className="row justify-content-center p-2">
                      <FontAwesomeIcon icon={faSpinner} className={`fa-spin ${styles.spinSize}`} />
                    </div>
                  </div>
                ) : fetchError ? (
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="alert alert-warning" role="alert">
                        {{ fetchError }}
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <button className="btn btn-link btn-sm" title="Actualizar" type="button" onClick={loadSections} >
                        <FontAwesomeIcon icon={faCloudDownloadAlt} size="sm" /> &nbsp;Reintentar
                      </button>
                    </div>
                  </div>
                ) : null
              )}
            </div>
          )
        )
      }
    </>
  );
};

export default Dashboard;